<template>
	<!-- 限时秒杀设置 -->
	<div class="seckill-set el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
			<a-form-item >
				<template #label>
					<span>秒杀活动开始提醒</span>
					<a-tooltip>
						<template #title>
							<div><img  src="@/assets/img/notice_wxapp/seckill-remind-notice.png" alt="" ></div>
						</template>
						<i class="ri-information-line"></i>
					</a-tooltip>
				</template>
				<a-input v-model:value="form.seckill_remind_notice"></a-input>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }">
				<a-button type="primary" @click="submitSave" >保存</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import setModel from '@/api/set';
export default{
	setup(){
		const state = reactive({
			form:{
				seckill_remind_notice:''
			}
		})

		setModel.getFarmSet(['seckill_remind_notice']).then(res=>{
			state.form.seckill_remind_notice = res.seckill_remind_notice || ''
		})

		const submitSave = ()=>setModel.setFarmSet(state.form)

		return{
			...toRefs(state),
			submitSave
		}
	}
}
</script>

<style>
</style>
